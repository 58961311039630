import React, { useEffect } from "react";
import logo from "../assets/logo.svg";
import { useNavigate } from "react-router-dom";
import LoginBackgroundImage from "../assets/LoginBackgroundImage";
import { useState } from "react";
import { loginUser } from "../services/requests";
import { UserTypeContext } from "../App";
import { useContext } from "react";
import {
  Box,
  Button,
  Image,
  PasswordInput,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { AiOutlineMail } from "react-icons/ai";
import { isEmail, useForm } from "@mantine/form";
import { failedNotify } from "../services/functions";
import Cookies from "js-cookie";
import LoginAsEmployeeModal from "../components/modal/LoginAsEmployeeModal";
import { useDisclosure } from "@mantine/hooks";

const Signin = () => {
  const navigate = useNavigate();
  const token = Cookies.get("userToken");
  const setUserType = useContext(UserTypeContext).setUserType;
  const [loader, setLoader] = useState(false);
  const [opened, { open, close }] = useDisclosure(false);
  const [userPerm, setUserPerm] = useState("");
  const [userData, setUserData] = useState({});

  const form = useForm({
    initialValues: {
      email: "",
      password: "",
    },
    validate: {
      email: isEmail("Invalid email"),
      password: (value) => (value.length < 8 ? "Invalid password" : null),
    },
  });

  // Redirect authenticated users
  useEffect(() => {
    if (token) {
      navigate("/dashboard");
    }
  });

  async function submitLogin(values, event) {
    event.preventDefault();
    setLoader(true);
    try {
      const res = await loginUser({
        email: values.email,
        password: values.password,
      });
      
      if (res?.data?.status) {
        if (res.data.data.user.user_type === "super_admin") {
          setUserType(res.data.data.user.user_type);
          localStorage.setItem("typeOfUser", res.data.data.user.user_type);
          localStorage.setItem("userEmail", res.data.data.user.email);
          Cookies.set("userToken", res.data.data.access_token);
          setTimeout(() => navigate("/dashboard", { replace: true }), 1500);
        } else if (res.data.data.user.user_type === "employee") {
          if (res.data.data.user.access_type === "mid_level_admin") {
            const todayDate = new Date().getTime();
            const expireDate = new Date(
              res.data.data.user.access_expires_at
            ).getTime();

            if (expireDate <= todayDate) {
              setUserPerm("Mid-Level Admin");
              setUserData(res.data.data);
              open();
            } else {
              setUserType(res.data.data.user.access_type);
              localStorage.setItem(
                "typeOfUser",
                res.data.data.user.access_type
              );
              Cookies.set("userToken", res.data.data.access_token);
              setTimeout(() => navigate("/dashboard", { replace: true }), 1500);
            }
          } else if (res.data.data.user.access_type === "standard_user") {
            const todayDate = new Date().getTime();
            const expireDate = new Date(
              res.data.data.user.access_expires_at
            ).getTime();

            if (expireDate <= todayDate) {
              setUserPerm("Standard User");
              setUserData(res.data.data);
              open();
            } else {
              setUserType(res.data.data.user.access_type);
              localStorage.setItem(
                "typeOfUser",
                res.data.data.user.access_type
              );
              Cookies.set("userToken", res.data.data.access_token);
              setTimeout(() => navigate("/dashboard", { replace: true }), 1500);
            }
          } else {
            setUserType(res.data.data.user.user_type);
            localStorage.setItem("typeOfUser", res.data.data.user.user_type);
            Cookies.set("userToken", res.data.data.access_token);
            setTimeout(() => navigate("/dashboard", { replace: true }), 1500);
          }
        }
      }
    } catch (error) {
      console.error(error);
      if (!error?.response) failedNotify("Login", "An error has occured!");
      else failedNotify("Login", error?.response?.data.message);
    } finally {
      setLoader(false);
    }
  }
  return (
    <main className="bg-[#F5F5F5] w-full h-screen ">
      <div className="w-full h-full flex ">
        <div className="hidden md:flex w-full max-w-[756px] h-full overflow-hidden bg-[#50F4FD0D] ">
          <div className="hidden md:flex h-full w-full items-center ">
            <LoginBackgroundImage />
          </div>
        </div>

        <div className="2xl:flex w-full h-full 2xl:justify-center">
          <div className="flex-1 px-5 py-4 lg:py-10 lg:px-[120px] max-w-7xl h-full 2xl:flex 2xl:flex-col 2xl:justify-center ">
            <div className="w-[158px] h-12">
              <Image
                src={logo}
                alt="Luchismart logo"
                className="w-full h-full"
              />
            </div>

            <Title className="text-3xl font-semibold font-poppins text-black/[0.85] mt-8 xl:mt-[60px] ">
              Login
            </Title>
            <Text className="text-xls capitalize font-semibold text-black/50 mb-8 mt-2">
              Welcome back
            </Text>
            <form
              onSubmit={form.onSubmit((values, e) => submitLogin(values, e))}
              className="w-full max-w-md"
            >
              <Box className="w-full">
                <TextInput
                  placeholder="Example@mail.com"
                  label="Email"
                  withAsterisk
                  size="lg"
                  radius={4}
                  icon={<AiOutlineMail size={20} />}
                  styles={(theme) => ({
                    label: {
                      paddingBottom: 4,
                      fontSize: 16,
                      fontWeight: 500,
                    },
                    input: {
                      borderColor: theme.colors.luchiPrimary[0],
                      borderWidth: 1,
                      "&:focus": {
                        borderColor: theme.colors.luchiPrimary[0],
                      },
                    },
                  })}
                  {...form.getInputProps("email")}
                />

                <PasswordInput
                  label="Password"
                  size="lg"
                  radius={4}
                  withAsterisk
                  error="Invalid password"
                  styles={(theme) => ({
                    label: {
                      paddingBottom: 4,
                      fontSize: 16,
                      marginTop: 16,
                      fontWeight: 500,
                    },
                    input: {
                      borderColor: theme.colors.luchiPrimary[0],
                      borderWidth: 1,
                      "&:focus-within": {
                        borderColor: theme.colors.luchiPrimary[0],
                      },
                    },
                  })}
                  {...form.getInputProps("password")}
                />

                <Button
                  type="submit"
                  variant="white"
                  className="bg-luchiPrimary text-white text-base font-bold capitalize hover:bg-luchiPrimary/80  "
                  size="md"
                  radius={6}
                  mt={24}
                  color="yellow"
                  fullWidth
                  loading={loader}
                  loaderPosition="right"
                >
                  login
                </Button>
              </Box>
            </form>
          </div>
        </div>
      </div>
      <LoginAsEmployeeModal
        opened={opened}
        close={close}
        permission={userPerm}
        data={userData}
      />
    </main>
  );
};

export default Signin;
