import { Modal, Box, Button, Textarea, TextInput } from "@mantine/core";
import { useContext } from "react";
import { UserTypeContext } from "../../App";
import { DepartmentLocation } from "../singleSelect/DepartmentLocation";
import DepartmentSelect from "../singleSelect/DepartmentSelect";

export default function EditHardwareModal({
  opened,
  close,
  loader,
  form,
  branchID,
  editMethod,
}) {
  const userType = useContext(UserTypeContext).userType;

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => { }}
        centered
        withCloseButton={true}
        title="Edit Hardware Device"
      >
        <form
          onSubmit={form.onSubmit((values, e) => editMethod(values, e))}
          className="w-full"
        >
          <Box>
            <TextInput
              withAsterisk
              label="Device ID"
              size="lg"
              readOnly
              placeholder="Enter device ID"
              radius={4}
              styles={(theme) => ({
                label: {
                  paddingBottom: 4,
                  fontSize: 16,
                  marginTop: 14,
                  fontWeight: 500,
                },
                input: {
                  borderWidth: 1,
                  backgroundColor: "#e2e3e5",
                  fontSize: 14,
                  "&:focus-within": {
                    borderColor: theme.colors.luchiPrimary[0],
                  },
                },
              })}
              {...form.getInputProps("deviceid")}
            />
            <TextInput
              withAsterisk
              label="Device Name"
              size="lg"
              placeholder="Specific name for device"
              radius={4}
              styles={(theme) => ({
                label: {
                  paddingBottom: 4,
                  fontSize: 16,
                  marginTop: 14,
                  fontWeight: 500,
                },
                input: {
                  borderWidth: 1,
                  fontSize: 14,
                  "&:focus-within": {
                    borderColor: theme.colors.luchiPrimary[0],
                  },
                },
              })}
              {...form.getInputProps("name")}
            />
            <Textarea
              withAsterisk
              label="Description"
              size="md"
              placeholder="Eg: Device for marketing department"
              radius={4}
              styles={(theme) => ({
                label: {
                  paddingBottom: 4,
                  fontSize: 16,
                  marginTop: 14,
                  fontWeight: 500,
                },
                input: {
                  borderWidth: 1,
                  fontSize: 14,
                  "&:focus-within": {
                    borderColor: theme.colors.luchiPrimary[0],
                  },
                },
              })}
              {...form.getInputProps("description")}
            />
          </Box>
          <Box my={14}>
            {userType === "super_admin" && <DepartmentLocation form={form} />}
          </Box>
          <Box mb={20}>
            <DepartmentSelect form={form} branchID={branchID} />
          </Box>
          <Box className="flex space-x-3">
            <Button
              onClick={close}
              fullWidth
              variant="outline"
              className="text-sm font-medium text-red-900 capitalize border-red-800 "
              type="button"
            >
              cancel
            </Button>
            <Button
              fullWidth
              loading={loader}
              loaderPosition="right"
              color="yellow"
              variant="white"
              className="bg-luchiPrimary text-sm font-medium text-white capitalize "
              type="submit"
            >
              update
            </Button>
          </Box>
        </form>
      </Modal>
    </>
  );
}
