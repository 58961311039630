import {
  AiFillUnlock,
  AiFillLock,
  AiFillEdit,
  AiOutlineSetting,
  AiOutlinePlusCircle,
  AiFillCheckCircle,
  AiFillExclamationCircle,
} from "react-icons/ai";
import { useState } from "react";
import {
  useFetchDevices,
  useFetchDevicesByBranchId,
} from "../../../hooks/team/hooks";
import {
  lockDevice,
  unlockDevice,
  updateHardwareDevice,
} from "../../../services/requests";
import { mutate } from "swr";
import { failedNotify, successNotify } from "../../../services/functions";
import {
  Box,
  Group,
  ActionIcon,
  Paper,
  Text,
  Badge,
  Loader,
  Pagination,
} from "@mantine/core";
import { isNotEmpty, useForm } from "@mantine/form";
import HeaderBar from "../../../components/headers/HeaderBar";
import { useDisclosure } from "@mantine/hooks";
import EditHardwareModal from "../../../components/modal/EditHardwareModal";
import { useEffect } from "react";
import useCustomPaginate from "../../../hooks/useCustomPagination";
import EditControlPanelDevice from "../../../components/modal/EditControlPanelDevice";
import { useCurrentUser } from "../../../hooks/useCurrentUser";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export default function Hardwares() {
  const { user } = useCurrentUser();
  const [branchID, setBranchID] = useState(
    user?.user_type !== "super_admin" ? user?.branch?.id : ""
  );
  const { devices, filteredData, isLoading, error } = useFetchDevices();
  const { branchDevices } = useFetchDevicesByBranchId(branchID);
  const [btnLoader, setBtnLoader] = useState(false);
  const [opened, { open, close }] = useDisclosure(false);
  const [openedPanel, { open: openPanel, close: closePanel }] =
    useDisclosure(false);
  const [dataFilter, setDataFilter] = useState({
    data: [],
    fetching: false,
    failed: {},
  });
  // for filtering devices by branch
  const [teamDevice, setTeamDevice] = useState({
    branchLocation: branchID,
  });
  const { currentPage, setCurrentPage, paginatedData, totalPages } =
    useCustomPaginate(dataFilter && dataFilter.data, 10);
  const form = useForm({
    initialValues: {
      id: "",
      name: "",
      deviceid: "",
      deptLocationID: branchID,
      deptID: "",
      description: "",
    },
    validate: {
      name: isNotEmpty("Compulsory field"),
      deptLocationID: isNotEmpty("Compulsory field"),
      deptID: isNotEmpty("Compulsory field"),
      description: isNotEmpty("Compulsory field"),
    },
  });
  const [hardwares, setHardwares] = useState([])
  const [departments, setDepartment] = useState([])
  const [branches, setBranches] = useState([])
  useEffect(() => {
    // alert(JSON.stringify(user))
    fetch(`https://api.luchismart.com/getBranches.php?id=${user.organization.id}`, {
      method: "GET"
    })
      .then(res => res.json())
      .then(data => {
        setBranches(data)
      })
    fetch(`https://api.luchismart.com/getDepartments.php?id=${user.organization.id}`, {
      method: "GET"
    })
      .then(res => res.json())
      .then(data => {
        setDepartment(data)
      })
    fetch(`https://getintellisoft.com/server/luchitec/getHardwares.php?id=${user.email}`, {
      method: "GET"
    })
      .then(response => response.json())
      .then(data => {
        // alert(JSON.stringify(data))
        setHardwares(data)
      })
  }, [])

  // updates branchID
  const newBranchID = form.getInputProps("deptLocationID").value;
  useEffect(() => {
    if (teamDevice) {
      setBranchID(teamDevice.branchLocation);
    }
  }, [teamDevice]);

  useEffect(() => {
    if (!branchID) {
      setDataFilter((dataFilter) => ({
        ...dataFilter,
        data: devices,
      }));
    } else {
      setDataFilter((dataFilter) => ({
        ...dataFilter,
        data: branchDevices,
      }));
    }
  }, [branchDevices, branchID, devices, error, isLoading]);

  async function updateDevice(values, event) {
    event.preventDefault();

    setBtnLoader(true);
    const details = {
      deviceId: values.id ? values.id : "",
      name: values.name,
      branch_id: values.deptLocationID,
      department_id: values.deptID,
      description: values.description,
    };
    const formData = new FormData();
    formData.append("id", values.id)
    formData.append("dept", values.deptID)
    formData.append("reader_name", values.name)
    formData.append("desc", values.description)
    fetch(`https://getintellisoft.com/server/luchitec/editReader.php`, {
      method: "POST",
      body: formData
    })
      .then(res => res.json())
      .then(data => {
        alert(data.message)
        if (data.code == 200) {
          close(0)
          form.reset()
        } else {
          return null;
        }
      })
    // const res = await updateHardwareDevice(details);
    // if (res.status) {
    //   successNotify("Device Edit Status", res.message);
    //   mutate("api/all-devices");
    //   const getIDs = localStorage.getItem("devices");
    //   let devices = [];
    //   if (getIDs === null) {
    //     devices.push({
    //       deviceid: values.deviceid,
    //       devicename: values.name
    //     })
    //     localStorage.setItem("devices", JSON.stringify(devices))
    //   } else {
    //     devices = JSON.parse(getIDs);
    //     devices.push({
    //       deviceid: values.deviceid,
    //       devicename: values.name
    //     })
    //     localStorage.setItem("devices", JSON.stringify(devices))
    //   }
    //   close(0);
    //   form.reset();
    // } else {
    //   failedNotify("Device Edit Status", res.response.data.message);
    // }
    // setBtnLoader(false);
  }

  async function handleEdit(deviceDetails) {
    open();
    form.setValues({
      id: deviceDetails.id,
      name: deviceDetails.reader_name,
      deviceid: deviceDetails.serial_num,
      deptLocationID: deviceDetails.location,
      deptID: deviceDetails.dept,
      description: deviceDetails.description ? deviceDetails.description : "",
    });
  }
  async function handleDeviceLock(deviceId) {
    const res = await lockDevice(deviceId);
    if (res.status) {
      successNotify("Lock status", res.message);
      mutate("api/all-devices");
    }
  }
  async function handleDeviceUnlock(deviceId) {
    const res = await unlockDevice(deviceId);
    if (res.status) {
      successNotify("Unlock status", res.message);
      mutate("api/all-devices");
    }
  }

  function handleConfigure() {
    openPanel();
  }
  const [showReaders, setShowReaders] = useState(false)
  const [activeController, setActiveController] = useState("")
  const [refresh, setRefresh] = useState(false)
  const [readers, setReaders] = useState([])
  const [isWebLoading, setIsLoading] = useState(false)
  return (
    <div className="w-full max-w-7xl relative h-full ">
      <div className="w-full mb-5 ">
        <HeaderBar
          title="Controller(s)"
          setData={setTeamDevice}
          user={user}
          show={true}
        />
      </div>
      {
        hardwares.length == 0 ?
          <Text>No device/hardware found</Text>
          :
          <></>
      }
      <button onClick={() => {
        return handleConfigure()
      }} className="relative w-full max-w-xl bg-luchiPrimary rounded-md mb-2" style={{ width: "auto", fontSize: 12, fontWeight: "900", padding: 10, color: "#fff" }}>Add New Controller</button>
      <div className="w-full flex overflow-x-auto whitespace-nowrap scroll-smooth">

        <>
          {hardwares &&
            hardwares.filter(ex => ex.location.includes(teamDevice.branchLocation)).map((device) => {

              return (
                <Paper
                  shadow="md"
                  p="sm"
                  key={device.id}
                  className="relative bg-luchiPrimary rounded-md mb-2 mr-2"
                  style={{ maxWidth: 500, width: 400 }}
                >
                  <Group
                    spacing={2}
                    className="flex items-center absolute top-1 right-2 "
                  >

                    <>
                      {/* <ActionIcon
                        onClick={() => handleEdit(device)}
                        title="edit"
                        className="bg-transparent hover:bg-transparent"
                      >
                        <AiFillEdit size="1.125rem" color="yellow" />
                      </ActionIcon> */}

                      {device && device.status === 1 ? (
                        <ActionIcon title="unlock" variant="transparent">
                          <AiFillUnlock
                            onClick={() => handleDeviceUnlock(device.id)}
                            size="1.125rem"
                            color="white"
                          />
                        </ActionIcon>
                      ) : (
                        <ActionIcon
                          onClick={() => handleDeviceLock(device.id)}
                          title="lock"
                          variant="transparent"
                        >
                          <AiFillLock size="1.125rem" color="white" />
                        </ActionIcon>
                      )}
                    </>

                  </Group>
                  <Box className="w-full">
                    <Text className="font-medium text-white text-sm uppercase">
                      {device.tag_name}
                    </Text>
                    <Text
                      truncate
                      maw={400}
                      className="font-normal text-black text-xs normal-case mb-2 italic w-full"
                    >
                      {branches.filter(ex => ex.id === device.location).length > 0 ? branches.filter(ex => ex.id === device.location)[0].name : "N/A"}
                    </Text>
                    {device.tag_name.toLowerCase() !== "control panel" && (
                      <>
                        {device && device.status === 1 ? (
                          <Badge
                            pl={0}
                            size="sm"
                            radius={0}
                            variant="light"
                            className="bg-transparent text-white font-medium"
                            leftSection={
                              <AiFillUnlock title="unlock" size="1rem" />
                            }
                          >
                            unlocked
                          </Badge>
                        ) : (
                          <Badge
                            pl={0}
                            size="sm"
                            radius={0}
                            variant="light"
                            className="bg-transparent text-white font-medium"
                            leftSection={
                              <AiFillLock title="lock" size="1rem" />
                            }
                          >
                            locked
                          </Badge>
                        )}
                      </>
                    )}
                    <button
                      onClick={() => {
                        setIsLoading(true)
                        setShowReaders(true)
                        setActiveController(device.tag_name)
                        fetch(`https://getintellisoft.com/server/luchitec/getReaders.php?id=${device.serial_num}`, {
                          method: "GET"
                        })
                          .then(res => res.json())
                          .then(data => {
                            setReaders(data)
                            setTimeout(() => {
                              setIsLoading(false)
                            }, 2000)

                          })
                      }}
                      style={{ float: "right", marginTop: 20, color: "#fff", fontWeight: "bold" }}>
                      View
                    </button>
                  </Box>
                </Paper>
              )
            }
            )}

        </>

      </div>
      {
        // <>
        //   {paginatedData &&
        //     paginatedData.map((device) => {
        //       if (device.name.toLowerCase() !== "control panel") {
        //         return null
        //       }
        //       return (
        //         <Paper
        //           shadow="md"
        //           p="sm"
        //           key={device.id}
        //           className="relative w-full max-w-xl bg-luchiPrimary rounded-md mb-2"
        //         >
        //           <Group
        //             spacing={2}
        //             className="flex items-center absolute top-1 right-2 "
        //           >
        //             {device.name.toLowerCase() === "control panel" ? (
        //               <ActionIcon
        //                 title="configure control panel"
        //                 variant="transparent"
        //               >
        //                 <AiOutlinePlusCircle
        //                   onClick={handleConfigure}
        //                   size="2.125rem"
        //                   color="white"
        //                 />
        //               </ActionIcon>
        //             ) : (
        //               <>
        //                 <ActionIcon
        //                   onClick={() => handleEdit(device)}
        //                   title="edit"
        //                   className="bg-transparent hover:bg-transparent"
        //                 >
        //                   <AiFillEdit size="1.125rem" color="yellow" />
        //                 </ActionIcon>

        //                 {device && device.locked === 1 ? (
        //                   <ActionIcon title="unlock" variant="transparent">
        //                     <AiFillUnlock
        //                       onClick={() => handleDeviceUnlock(device.id)}
        //                       size="1.125rem"
        //                       color="white"
        //                     />
        //                   </ActionIcon>
        //                 ) : (
        //                   <ActionIcon
        //                     onClick={() => handleDeviceLock(device.id)}
        //                     title="lock"
        //                     variant="transparent"
        //                   >
        //                     <AiFillLock size="1.125rem" color="white" />
        //                   </ActionIcon>
        //                 )}
        //               </>
        //             )}
        //           </Group>
        //           <Box className="w-full">
        //             <Text className="font-medium text-white text-sm uppercase">
        //               {device.name}
        //             </Text>
        //             <Text
        //               truncate
        //               maw={400}
        //               className="font-normal text-black text-xs normal-case mb-2 italic w-full"
        //             >
        //               Configure new Control Panel
        //             </Text>
        //             {device.name.toLowerCase() !== "control panel" && (
        //               <>
        //                 {device && device.locked === 0 ? (
        //                   <Badge
        //                     pl={0}
        //                     size="sm"
        //                     radius={0}
        //                     variant="light"
        //                     className="bg-transparent text-white font-medium"
        //                     leftSection={
        //                       <AiFillUnlock title="unlock" size="1rem" />
        //                     }
        //                   >
        //                     unlocked
        //                   </Badge>
        //                 ) : (
        //                   <Badge
        //                     pl={0}
        //                     size="sm"
        //                     radius={0}
        //                     variant="light"
        //                     className="bg-transparent text-white font-medium"
        //                     leftSection={
        //                       <AiFillLock title="lock" size="1rem" />
        //                     }
        //                   >
        //                     locked
        //                   </Badge>
        //                 )}
        //               </>
        //             )}
        //           </Box>
        //         </Paper>
        //       )
        //     }
        //     )}

        // </>
      }
      {
        isWebLoading ?
          <div style={{ marginTop: 20 }}>
            <Skeleton style={{ maxWidth: 550, height: 30, marginBottom: 30 }} count={1} />
            <Skeleton style={{ maxWidth: 550, height: 80 }} count={4} />
          </div>
          :

          showReaders ?
            <>
              <div className="w-full mb-5 mt-3">
                <HeaderBar
                  title={activeController + " Readers"}
                  // setData={{}}
                  // user={user}
                  show={true}
                />
              </div>
              <div className="w-full relative">
                {
                  readers.length == 0 ?
                    <Text>No device/readers found</Text>
                    :
                    <></>
                }
                <>
                  {readers &&
                    readers.map((device) => {

                      return (
                        <Paper
                          shadow="md"
                          p="sm"
                          key={device.id}
                          className="relative w-full max-w-xl bg-luchiPrimary rounded-md mb-2"
                        >
                          <Group
                            spacing={2}
                            className="flex items-center absolute top-1 right-2 "
                          >

                            <>
                              <ActionIcon
                                onClick={() => handleEdit(device)}
                                title="edit"
                                className="bg-transparent hover:bg-transparent"
                              >
                                <AiFillEdit size="1.125rem" color="yellow" />
                              </ActionIcon>

                              {device && device.status === 1 ? (
                                <ActionIcon title="unlock" variant="transparent">
                                  <AiFillCheckCircle
                                    onClick={() => handleDeviceUnlock(device.id)}
                                    size="1.125rem"
                                    color="white"
                                  />
                                </ActionIcon>
                              ) : (
                                <ActionIcon
                                  onClick={() => handleDeviceLock(device.id)}
                                  title="lock"
                                  variant="transparent"
                                >
                                  <AiFillExclamationCircle size="1.125rem" color="white" />
                                </ActionIcon>
                              )}
                            </>

                          </Group>
                          <Box className="w-full">
                            <Text className="font-medium text-white text-md uppercase">
                              {device.reader_name === null ? "Department not set" : device.reader_name}
                            </Text>
                            <Text className="font-medium text-dark text-sm uppercase">
                              Department: {device.dept === null ? "Not assigned" : departments.filter(ex => ex.id === device.dept).length > 0 ? departments.filter(ex => ex.id === device.dept)[0].name : "N/A" }
                            </Text>
                            <Text className="font-medium text-white text-sm uppercase">
                              Door {device.door_num}
                            </Text>
                            <Text style={{ textAlign: "right" }} className="font-medium text-white text-sm">
                              {device.description}
                            </Text>

                          </Box>
                        </Paper>
                      )
                    }
                    )}

                </>

              </div>
            </>
            :
            <></>
      }


      <EditHardwareModal
        opened={opened}
        close={close}
        loader={btnLoader}
        form={form}
        branchID={newBranchID}
        editMethod={updateDevice}
      />
      <Box className=" mt-14 "></Box>
      <Box className="w-full absolute bottom-0 ">
        <Pagination
          color="cyan"
          position="center"
          page={currentPage}
          onChange={setCurrentPage}
          total={totalPages ? totalPages : 0}
          size="lg"
        />
      </Box>
      <EditControlPanelDevice
        opened={openedPanel}
        close={closePanel}
        devices={filteredData}
      />
    </div>
  );
}
