import axios from "axios";
import Cookies from "js-cookie";
import { refreshToken } from "./requests";

const axiosInstance = axios.create({
  baseURL: "https://api.luchismart.com/",
  withCredentials: false,
});

axiosInstance.interceptors.request.use(
  function (config) {
    const token = Cookies.get("userToken");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error?.response?.status === 401) {
      // The token has expired, so refresh it and try again
      const response = await refreshToken();
      if (response.status) {
        const newToken = response?.data?.access_token;
        Cookies.set("userToken", newToken);
        axios.defaults.headers.common["Authorization"] = `Bearer ${newToken}`;
        return axios.request(error.config);
      } else {
        // throw new Error(response.data.message);
        console.log(response);
      }
    } else {
      return Promise.reject(error);
    }
  }
);

export default axiosInstance;
