import { Select } from "@mantine/core";
import { useState } from "react";
import useFetchBranches from "../../hooks/useFetchBranches";
import { useEffect } from "react";

export default function BranchLocation({ setMethod }) {
  const [locationID, onLocationIdChange] = useState("");
  const { branchLocations } = useFetchBranches();

  useEffect(() => {
    if (locationID) {
      setMethod((details) => ({
        ...details,
        branchLocation: locationID,
      }));
      
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationID]);
  return (
    <Select
      placeholder="Location"
      value={locationID}
      size="lg"
      clearable
      nothingFound="no branches"
      className="w-full"
      onChange={onLocationIdChange}
      data={branchLocations && branchLocations}
      styles={(theme) => ({
        item: {
          fontSize: 14,
          paddingTop: 8,
          paddingBottom: 8,
          "&[data-selected]": {
            "&, &:hover": {
              backgroundColor: theme.colors.luchiPrimary[0],
              color: theme.white,
            },
          },
          "&[data-hovered]": {},
        },
        input: {
          fontSize: 16,
          borderWidth: 1,
          "&:focus": {
            borderColor: theme.colors.luchiPrimary[0],
          },
        },
        label: {
          paddingBottom: 4,
          fontSize: 16,
        },
      })}
    />
  );
}
