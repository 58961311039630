import "./App.css";
import {
  RouterProvider,
  createBrowserRouter,
  Navigate,
} from "react-router-dom";
import { createContext, useState, lazy, Suspense } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import SuperAdminDashboard from "./pages/dashboards/superAdminDashboard";
import UserProfile from "./pages/superadmin/user/userProfile";
import ProfileSetting from "./pages/superadmin/user/profile-setting";
import EmployeesPage from "./pages/superadmin/team/employees-page";
import CreateDepartment from "./pages/superadmin/team/create-department";
import Addemployee from "./pages/superadmin/team/add-employee";
import UtilityManagement from "./pages/superadmin/utilityManagement";
import TimeAttendance from "./pages/superadmin/utilityManagement/time-attendance";
import Attendances from "./pages/superadmin/utilityManagement/attendances";
import SingleAttendance from "./pages/superadmin/utilityManagement/[date]";
import AttendanceByDay from "./pages/superadmin/utilityManagement/attendance-by-day";
import EmployeeLeave from "./pages/managerpage/employee-leave";
import OverTime from "./pages/managerpage/overtime";
import ProductivityManagement from "./pages/superadmin/utilityManagement/productivity-management";
import UsersLocationMap from "./pages/managerpage/users-location-map";
import Jobs from "./pages/managerpage/jobs";
import AddJob from "./pages/managerpage/add-job";
import Activity from "./pages/managerpage/activity";
import OccupancySingleAttendance from "./components/Users/SuperAdmin/OccupancySingleAttendance";
import OccupancyAttendanceTable from "./components/Users/SuperAdmin/OccupancyAttendanceTable";
import MonthlyLogs from "./pages/superadmin/event/monthly-logs";
import CreateBranch from "./pages/superadmin/organization/create-branch";
import CreditDebitCard from "./pages/superadmin/organization/credit-debit-card";
import PayPal from "./pages/superadmin/organization/paypal";
import BillingAddress from "./pages/superadmin/organization/billing-address";
import Hardwares from "./pages/superadmin/organization/hardwares";
import Permissions from "./pages/superadmin/organization/permissions";
import GrantAccess from "./pages/superadmin/organization/grant-permission";
import PaymentHistory from "./pages/superadmin/organization/payment-history";
import Organogram from "./pages/superadmin/organization/organogram";
import Settings from "./pages/superadmin/organization/settings";
import SetRolePermission from "./pages/superadmin/organization/set-role-permission";
import Signin from "./pages/Signin";
import ChangePassword from "./pages/change-password";
import MidLevelAdminDashboard from "./pages/dashboards/midLevelAdminDashboard";
import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import StandardUserDashboard from "./pages/dashboards/standardUserDashboard";
import Cookies from "js-cookie";
import DepartmentEmployeesPage from "./pages/superadmin/team/department-employees";
import DashboardLayout from "./components/Layout/DashboardLayout";
import PermissionDenied from "./pages/errorpages/403";
import NotFoundPage from "./pages/errorpages/404";
import StandardUsers from "./pages/superadmin/user/standard-users";
import CreateSuperAdmin from "./pages/register";
import ErrorBoundary from "./components/ErrorBoundary";
import EventHistory from "./pages/superadmin/event";
import Notification from "./pages/superadmin/user/notifications";
import EmailPreferences from "./pages/superadmin/user/email-preference";
import LoginSecurity from "./pages/superadmin/user/security";
import DataEventDayLogs from "./pages/superadmin/event/data-event-day-logs";
import VideoEventDayLogs from "./pages/superadmin/event/video-event-day-logs";
import Reports from "./pages/reports";
import ActivityReport from "./pages/reports/activity-report";
import DailyTotalReport from "./pages/reports/daily-total";
import AppUrls from "./pages/reports/app-urls";
import Capacity from "./pages/advancedAccess/capacity";
import OccupancyManagement from "./pages/advancedAccess/occupancy-tracking";
import { SWRConfig } from "swr";
import Intercom from "./pages/advancedAccess/intercom";
import { useCurrentUser } from "./hooks/useCurrentUser";
import TempValueProvider from "./context/TempValueContext";

const Administrators = lazy(() => import("./pages/superadmin/user"));
const Team = lazy(() => import("./pages/superadmin/team"));
const Billings = lazy(() => import("./pages/superadmin/organization/billings"));
const OrganizationSetup = lazy(() => import("./pages/superadmin/organization"));
const HelpSupport = lazy(() => import("./pages/help-support"));
const Credentials = lazy(() =>
  import("./pages/superadmin/organization/credentials")
);

export const UserTypeContext = createContext({
  userType: "",
  setUserType: () => {},
});

function App() {
  const [userType, setUserType] = useState(localStorage.getItem("typeOfUser"));

  const routes = createBrowserRouter([
    {
      path: "/",
      element: <DashboardLayout />,
      errorElement: (
        <div className="h-screen w-full">
          <NotFoundPage />
        </div>
      ),
      children: [
        {
          index: true,
          element: (
            <RequireAuth>
              {userType === "super_admin" && <SuperAdminDashboard />}
              {userType === "mid_level_admin" && <MidLevelAdminDashboard />}
              {userType === "standard_user" && <StandardUserDashboard />}
              {userType === "employee" && <StandardUserDashboard />}
            </RequireAuth>
          ),
        },
        {
          path: "/dashboard",
          element: (
            <RequireAuth>
              {userType === "super_admin" && <SuperAdminDashboard />}
              {userType === "mid_level_admin" && <MidLevelAdminDashboard />}
              {userType === "standard_user" && <StandardUserDashboard />}
              {userType === "employee" && <StandardUserDashboard />}
            </RequireAuth>
          ),
        },
        {
          path: "/user",
          element: (
            <StandardUser>
              <Administrators />
            </StandardUser>
          ),
        },
        {
          path: "/user/standard-users",
          element: (
            <StandardUser>
              <StandardUsers />
            </StandardUser>
          ),
        },
        {
          path: "/user/:userId",
          element: (
            <RequireAuth>
              <UserProfile />
            </RequireAuth>
          ),
        },
        {
          path: "/user/:userId/profile",
          element: (
            <RequireAuth>
              <ProfileSetting />
            </RequireAuth>
          ),
        },
        {
          path: "/user/:userId/notification",
          element: (
            <RequireAuth>
              <StandardSub>
                <Notification />
              </StandardSub>
            </RequireAuth>
          ),
        },
        {
          path: "/user/:userId/emailPreference",
          element: (
            <RequireAuth>
              <StandardSub>
                <EmailPreferences />
              </StandardSub>
            </RequireAuth>
          ),
        },
        {
          path: "/user/:userId/security",
          element: (
            <RequireAuth>
              <StandardSub>
                <LoginSecurity />
              </StandardSub>
            </RequireAuth>
          ),
        },
        {
          path: "/team",
          element: (
            <StandardUser>
              <Team />
            </StandardUser>
          ),
        },
        {
          path: "/team/:deptId",
          element: (
            <StandardUser>
              <DepartmentEmployeesPage />
            </StandardUser>
          ),
        },
        {
          path: "/team/employees",
          element: (
            <AdminAuth>
              <EmployeesPage />
            </AdminAuth>
          ),
        },
        {
          path: "/team/create-department",
          element: (
            <AdminAuth>
              <CreateDepartment />
            </AdminAuth>
          ),
        },
        {
          path: "/team/add-employee",
          element: (
            <AdminAuth>
              <Addemployee />
            </AdminAuth>
          ),
        },
        {
          path: "/utility-management",
          element: (
            <RequireAuth>
              <EnterpriseSub>
                <UtilityManagement />
              </EnterpriseSub>
            </RequireAuth>
          ),
        },
        {
          path: "/utility-management/time-attendance",
          element: (
            // <RequireAuth>
            //    <EnterpriseSub>
                <TimeAttendance />
            //  </EnterpriseSub>
            //  </RequireAuth>
          ),
        },
        {
          path: "/utility-management/time-attendance/attendances",
          element: (
            <RequireAuth>
              <EnterpriseSub>
                <Attendances />
              </EnterpriseSub>
            </RequireAuth>
          ),
        },
        {
          path: "/utility-management/time-attendance/attendances/:date",
          element: (
            <RequireAuth>
              <EnterpriseSub>
                <SingleAttendance />
              </EnterpriseSub>
            </RequireAuth>
          ),
        },
        {
          path: "/utility-management/time-attendance/attendances/:date/:day",
          element: (
            <RequireAuth>
              <EnterpriseSub>
                <AttendanceByDay />
              </EnterpriseSub>
            </RequireAuth>
          ),
        },
        {
          path: "/utility-management/time-attendance/leave",
          element: (
            <RequireAuth>
              <EnterpriseSub>
                <EmployeeLeave />
              </EnterpriseSub>
            </RequireAuth>
          ),
        },
        {
          path: "/utility-management/time-attendance/overtime",
          element: (
            <RequireAuth>
              <EnterpriseSub>
                <OverTime />
              </EnterpriseSub>
            </RequireAuth>
          ),
        },
        {
          path: "/utility-management/productivity-management",
          element: (
            // <RequireAuth>
            //   <EnterpriseSub>
                <ProductivityManagement />
              // </EnterpriseSub>
            // </RequireAuth>
          ),
        },
        {
          path: "/utility-management/productivity-management/users-location-map",
          element: (
            // <RequireAuth>
            //   <EnterpriseSub>
                <UsersLocationMap />
              // </EnterpriseSub>
            // </RequireAuth>
          ),
        },
        {
          path: "/utility-management/productivity-management/jobs",
          element: (
            <RequireAuth>
              <EnterpriseSub>
                <Jobs />
              </EnterpriseSub>
            </RequireAuth>
          ),
        },
        {
          path: "/utility-management/productivity-management/jobs/add-job",
          element: (
            <RequireAuth>
              <EnterpriseSub>
                <AddJob />
              </EnterpriseSub>
            </RequireAuth>
          ),
        },
        {
          path: "/utility-management/productivity-management/activity",
          element: (
            // <RequireAuth>
            //   <EnterpriseSub>
                <Activity />
              // </EnterpriseSub>
            // </RequireAuth>
          ),
        },
        {
          path: "/utility-management/occupancy/:month",
          element: (
            <RequireAuth>
              <EnterpriseSub>
                <OccupancySingleAttendance />
              </EnterpriseSub>
            </RequireAuth>
          ), //change dynamic value later
        },
        {
          path: "/utility-management/occupancy/:month/day",
          element: (
            <RequireAuth>
              <EnterpriseSub>
                <OccupancyAttendanceTable />
              </EnterpriseSub>
            </RequireAuth>
          ), //change dynamic value later
        },
        {
          path: "/help",
          element: (
            <RequireAuth>
              <HelpSupport />
            </RequireAuth>
          ),
        },
        {
          path: "/advanced-access/capacity",
          element: (
            <RequireAuth>
              <StandardSub>
                <Capacity />
              </StandardSub>
            </RequireAuth>
          ),
        },
        {
          path: "/advanced-access/occupancy",
          element: (
            <RequireAuth>
              <StandardSub>
                <OccupancyManagement />
              </StandardSub>
            </RequireAuth>
          ),
        },
        {
          path: "/advanced-access/intercom",
          element: (
            <RequireAuth>
              <StandardSub>
                <Intercom />
              </StandardSub>
            </RequireAuth>
          ),
        },
        {
          path: "/organization-setup",
          element: (
            <AdminAuth>
              <OrganizationSetup />
            </AdminAuth>
          ),
        },
        {
          path: "/organization-setup/create-branch",
          element: (
            <SuperAdminAuth>
              <CreateBranch />
            </SuperAdminAuth>
          ),
        },
        {
          path: "/organization-setup/credentials",
          element: (
            <AdminAuth>
              <Credentials />
            </AdminAuth>
          ),
        },
        {
          path: "/organization-setup/billings",
          element: (
            <SuperAdminAuth>
              <Billings />
            </SuperAdminAuth>
          ),
        },
        {
          path: "/organization-setup/billings/card-method",
          element: (
            <SuperAdminAuth>
              <CreditDebitCard />
            </SuperAdminAuth>
          ),
        },
        {
          path: "/organization-setup/billings/paypal",
          element: (
            <SuperAdminAuth>
              <PayPal />
            </SuperAdminAuth>
          ),
        },
        {
          path: "/organization-setup/billings/billing-address",
          element: (
            <SuperAdminAuth>
              <BillingAddress />
            </SuperAdminAuth>
          ),
        },
        {
          path: "/organization-setup/hardwares",
          element: (
            <AdminAuth>
              <Hardwares />
            </AdminAuth>
          ),
        },
        {
          path: "/organization-setup/permissions",
          element: (
            <SuperAdminAuth>
              <Permissions />
            </SuperAdminAuth>
          ),
        },
        {
          path: "/organization-setup/permissions/grant-permission",
          element: (
            <SuperAdminAuth>
              <GrantAccess />
            </SuperAdminAuth>
          ),
        },
        {
          path: "/organization-setup/payment-history",
          element: (
            <SuperAdminAuth>
              <PaymentHistory />
            </SuperAdminAuth>
          ),
        },
        {
          path: "/organization-setup/organogram",
          element: (
            <AdminAuth>
              <Organogram />
            </AdminAuth>
          ),
        },
        {
          path: "/organization-setup/settings",
          element: (
            <SuperAdminAuth>
              <Settings />
            </SuperAdminAuth>
          ),
        },
        {
          path: "/organization-setup/settings/set-role-permission",
          element: (
            <SuperAdminAuth>
              <SetRolePermission />
            </SuperAdminAuth>
          ),
        },
        {
          path: "/event",
          element: (
            <StandardUser>
              {/* <StandardSub> */}
              <EventHistory />
              {/* </StandardSub> */}
            </StandardUser>
          ),
        },
        {
          path: "/event/monthly-logs/:monthYear",
          element: (
            <StandardUser>
              <StandardSub>
                <MonthlyLogs />
              </StandardSub>
            </StandardUser>
          ),
        },
        {
          path: "/event/monthly-logs/video/:day",
          element: (
            <StandardUser>
              <StandardSub>
                <VideoEventDayLogs />
              </StandardSub>
            </StandardUser>
          ),
        },
        {
          path: "/event/monthly-logs/data/:day",
          element: (
            <StandardUser>
              <DataEventDayLogs />
            </StandardUser>
          ),
        },
        {
          path: "/reports",
          element: (
            <AdminAuth>
              <StandardSub>
                <Reports />
              </StandardSub>
            </AdminAuth>
          ),
        },
        {
          path: "/reports/activity",
          element: (
            <AdminAuth>
              <StandardSub>
                <ActivityReport />
              </StandardSub>
            </AdminAuth>
          ),
        },
        {
          path: "/reports/daily-total",
          element: (
            <AdminAuth>
              <StandardSub>
                <DailyTotalReport />
              </StandardSub>
            </AdminAuth>
          ),
        },
        {
          path: "/reports/app-urls",
          element: (
            <AdminAuth>
              <StandardSub>
                <AppUrls />
              </StandardSub>
            </AdminAuth>
          ),
        },
      ],
    },
    {
      path: "/change-password",
      element: <ChangePassword />,
    },
    {
      path: "/register",
      element: <CreateSuperAdmin />,
    },
    {
      path: "/signin",
      element: <Signin />,
    },
  ]);

  return (
    <MantineProvider
      theme={{
        colors: {
          luchiPrimary: ["#37A7AE"],
        },
        fontFamily: {
          poppins: ["Poppins", "sans-serif"],
        },
        colorScheme: "light",
      }}
      withGlobalStyles
      withNormalizeCSS
    >
      <TempValueProvider>
        <Notifications position="top-right" />
        <ErrorBoundary>
          <SWRConfig
            value={{
              errorRetryCount: 3,
              dedupingInterval: 300000,
            }}
          >
            <UserTypeContext.Provider value={{ userType, setUserType }}>
              <Suspense
                fallback={
                  <div className="w-full h-screen pt-16 flex items-center justify-center">
                    <div
                      style={{
                        height: "100vh",
                        width: "100%",
                        backgroundColor: "white",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <p className="font-display capitalize text-base text-luchiPrimary font-semibold">
                        Loading content...
                      </p>
                    </div>
                  </div>
                }
              >
                <RouterProvider router={routes} />
              </Suspense>
              <ToastContainer autoClose={2000} />
            </UserTypeContext.Provider>
          </SWRConfig>
        </ErrorBoundary>
      </TempValueProvider>
    </MantineProvider>
  );
}

export default App;

function RequireAuth({ children }) {
  const userToken = Cookies.get("userToken");
  if (userToken) {
    return children;
  }
  return <Navigate to="/signin" />;
}

function AdminAuth({ children }) {
  const userToken = Cookies.get("userToken");
  const [userType] = useState(localStorage.getItem("typeOfUser"));

  if (userToken) {
    if (userType === "super_admin" || userType === "mid_level_admin") {
      return children;
    } else {
      return (
        <div className="flex items-center justify-center w-full h-full text-2xl ">
          <PermissionDenied />
        </div>
      );
    }
  }
  return <Navigate to="/signin" />;
}

function SuperAdminAuth({ children }) {
  const userToken = Cookies.get("userToken");
  const [userType] = useState(localStorage.getItem("typeOfUser"));

  if (userToken) {
    if (userType === "super_admin") {
      return children;
    } else {
      return (
        <div className="flex items-center justify-center w-full h-full text-2xl ">
          <PermissionDenied />
        </div>
      );
    }
  }
  return <Navigate to="/signin" />;
}

function StandardUser({ children }) {
  const userToken = Cookies.get("userToken");
  const [userType] = useState(localStorage.getItem("typeOfUser"));

  if (userToken) {
    if (
      userType === "standard_user" ||
      userType === "super_admin" ||
      userType === "mid_level_admin"
    ) {
      return children;
    } else {
      return (
        <div className="flex items-center justify-center w-full h-full text-2xl ">
          <PermissionDenied />
        </div>
      );
    }
  }
  return <Navigate to="/signin" />;
}

function StandardSub({ children }) {
  const { user } = useCurrentUser();
  const subscription = user?.organization?.subscription.toLowerCase();
  //remove the basic condition
  if (
    subscription === "standard" ||
    subscription === "enterprise" ||
    subscription === "basic"
  ) {
    return children;
  } else {
    return (
      <div className="flex items-center justify-center w-full h-full text-2xl ">
        <PermissionDenied />
      </div>
    );
  }
}

function EnterpriseSub({ children }) {
  const { user } = useCurrentUser();
  const subscription = user?.organization?.subscription.toLowerCase();

  if (subscription === "enterprise") {
    return children;
  } else {
    return (
      <div className="flex items-center justify-center w-full h-full text-2xl ">
        <PermissionDenied />
      </div>
    );
  }
}
