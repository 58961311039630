//hello
import { Link, useLocation } from "react-router-dom";
import {
  attendanceClockin,
  getAttendanceByNumberFetcher,
} from "../../../services/requests";
import useSWR from "swr";
import {
  cautionNotify,
  failedNotify,
  getMonthYear,
  successNotify,
} from "../../../services/functions";
import { useEffect, useRef, useState } from "react";
import { BiErrorAlt } from "react-icons/bi";
import { MdOutlineHourglassEmpty } from "react-icons/md";
import HeaderBar from "../../../components/headers/HeaderBar";
import {
  Box,
  Loader,
  Paper,
  Text,
  Title,
  Button,
  Group,
  Modal,
  Select,
  ScrollArea,
  ActionIcon,
  Badge,
} from "@mantine/core";
import Clock from "react-clock";
import "react-clock/dist/Clock.css";
import { useDisclosure } from "@mantine/hooks";
import { useCurrentUser } from "../../../hooks/useCurrentUser";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { AiFillLock, AiFillUnlock } from "react-icons/ai";
import { mutate } from "swr";
import {
  lockDevice,
  unlockDevice,
  updateHardwareDevice,
} from "../../../services/requests";
import { setDate } from "date-fns";

export default function TimeAttendance() {
  const { pathname } = useLocation();
  const { user } = useCurrentUser();
  const { data, isLoading, error } = useSWR(6, getAttendanceByNumberFetcher);
  const [displayAttendance, setDisplayAttendance] = useState([]);
  const [records, setRecords] = useState([])
  const [devices, setDevices] = useState([])
  const [refresh, setRefresh] = useState(false)
  useEffect(() => {
    const set = new Set();
    const tempArray = [];
    if (data) {
      data.filter((item) => {
        if (!set.has(getMonthYear(item.signin_at))) {
          set.add(getMonthYear(item.signin_at));
          tempArray.push(item);
        }
        return tempArray;
      });
      // setDisplayAttendance(tempArray);
    }
  }, [data]);
  const [hardwares, setHardwares] = useState([])
  const [departments, setDepartment] = useState([])
  const [branches, setBranches] = useState([])
  const [branchID, setBranchID] = useState(
    user?.user_type !== "super_admin" ? user?.branch?.id : ""
  );
  const [isWebLoading, setIsLoading] = useState(false)
  const [showReaders, setShowReaders] = useState(false)
  const [activeController, setActiveController] = useState("")
  const [activeControllerSerialNum, setActiveControllerNum] = useState("")
  const [readers, setReaders] = useState([])
  const [isData, setIsData] = useState(false)
  const [teamDevice, setTeamDevice] = useState({
    branchLocation: branchID,
  });
  useEffect(() => {
    // alert(JSON.stringify(user))
    fetch(`https://api.luchismart.com/getBranches.php?id=${user.organization.id}`, {
      method: "GET"
    })
      .then(res => res.json())
      .then(data => {
        setBranches(data)
      })
    fetch(`https://api.luchismart.com/getDepartments.php?id=${user.organization.id}`, {
      method: "GET"
    })
      .then(res => res.json())
      .then(data => {
        setDepartment(data)
      })
    fetch(`https://getintellisoft.com/server/luchitec/getHardwares.php?id=${user.email}`, {
      method: "GET"
    })
      .then(response => response.json())
      .then(data => {
        // alert(JSON.stringify(data))
        setHardwares(data)
      })
  }, [])
  useEffect(() => {
    const getDevice = localStorage.getItem("devices");

    if (getDevice !== null) {
      setDevices(JSON.parse(getDevice))
      setRefresh(!refresh)
    }
  }, [])
  async function handleDeviceLock(deviceId) {
    const res = await lockDevice(deviceId);
    if (res.status) {
      successNotify("Lock status", res.message);
      mutate("api/all-devices");
    }
  }
  async function handleDeviceUnlock(deviceId) {
    const res = await unlockDevice(deviceId);
    if (res.status) {
      successNotify("Unlock status", res.message);
      mutate("api/all-devices");
    }
  }
  return (
    <main className="relative w-full max-w-7xl ">
      <Box className="w-full flex">
        <HeaderBar
          title="time & attendance"
          data={{}}
          setData={{}}
          user={{}}
          show={false}
        />

        <AttendanceClock />
      </Box>
      {
        hardwares.length == 0 ?
          <Text>No device/hardware found</Text>
          :
          <></>
      }
      <div className="w-full flex overflow-x-auto whitespace-nowrap scroll-smooth">

        <>
          {hardwares &&
            hardwares.filter(ex => ex.location.includes(teamDevice.branchLocation)).map((device) => {

              return (
                <Paper
                  shadow="md"
                  p="sm"
                  key={device.id}
                  className="relative bg-luchiPrimary rounded-md mb-2 mr-2"
                  style={{ maxWidth: 500, width: 400 }}
                >
                  <Group
                    spacing={2}
                    className="flex items-center absolute top-1 right-2 "
                  >

                    <>
                      {/* <ActionIcon
                        onClick={() => handleEdit(device)}
                        title="edit"
                        className="bg-transparent hover:bg-transparent"
                      >
                        <AiFillEdit size="1.125rem" color="yellow" />
                      </ActionIcon> */}


                    </>

                  </Group>
                  <Box className="w-full">
                    <Text className="font-medium text-white text-sm uppercase">
                      {device.tag_name}
                    </Text>
                    <Text
                      truncate
                      maw={400}
                      className="font-normal text-black text-xs normal-case mb-2 italic w-full"
                    >
                      {branches.filter(ex => ex.id === device.location).length > 0 ? branches.filter(ex => ex.id === device.location)[0].name : "N/A"}
                    </Text>
                    {device.tag_name.toLowerCase() !== "control panel" && (
                      <>
                        {device && device.status === 1 ? (
                          <Badge
                            pl={0}
                            size="sm"
                            radius={0}
                            variant="light"
                            className="bg-transparent text-white font-medium"
                            leftSection={
                              <AiFillUnlock title="unlock" size="1rem" />
                            }
                          >
                            unlocked
                          </Badge>
                        ) : (
                          <Badge
                            pl={0}
                            size="sm"
                            radius={0}
                            variant="light"
                            className="bg-transparent text-white font-medium"
                            leftSection={
                              <AiFillLock title="lock" size="1rem" />
                            }
                          >
                            locked
                          </Badge>
                        )}
                      </>
                    )}
                    <button
                      onClick={() => {
                        setIsLoading(true)
                        setShowReaders(true)
                        setActiveController(device.tag_name)
                        setActiveControllerNum(device.serial_num)
                        fetch(`https://getintellisoft.com/server/luchitec/getReaders.php?id=${device.serial_num}`, {
                          method: "GET"
                        })
                          .then(res => res.json())
                          .then(data => {
                            setReaders(data)
                            setTimeout(() => {
                              setIsLoading(false)
                            }, 2000)

                          })
                      }}
                      style={{ float: "right", marginTop: 20, color: "#fff", fontWeight: "bold" }}>
                      View
                    </button>
                  </Box>
                </Paper>
              )
            }
            )}

        </>

      </div>
      {
        isWebLoading ?
          <div className="flex">
            <Skeleton style={{ width: 350, padding: 20, marginTop: 10, marginBottom: 30 }} count={1} />
            <Skeleton style={{ width: 350, padding: 20, marginTop: 10, marginBottom: 30, marginLeft: 10, }} count={1} />
            <Skeleton style={{ width: 150, padding: 20, marginTop: 10, marginBottom: 30, marginLeft: 10 }} count={1} />
          </div>
          :
          readers.length <= 0 ?
            <></>
            :
            <>
              <select id="reader" style={{ backgroundColor: "transparent", width: 350, borderRadius: 10, padding: 20, border: "1px solid #e2e3e5" }}
                onChange={(e) => {

                }}
              >
                <option value="option1">Select Readers to load attendance</option>
                {
                  readers.map((o, i) => {
                    return (
                      <option key={i} value={o.door_num}>{o.reader_name || "Door " + o.door_num}</option>
                    )
                  })
                }
              </select>
              <input id="date" defaultValue={new Date().toISOString().split('T')[0]} type="date" style={{ color: "#000", marginLeft: 10, backgroundColor: "transparent", width: 350, borderRadius: 10, padding: 20, border: "1px solid #e2e3e5" }} />
              <button onClick={() => {
                const reader = document.getElementById("reader").value;
                const date = document.getElementById("date").value

                setIsData(false)
                fetch(`https://getintellisoft.com/server/luchitec/getRecords.php?door=${reader}&date=${date}&serial=${activeControllerSerialNum}`)
                  .then(response => response.json())
                  .then(data => {
                    setRecords(data)
                    setRefresh(!refresh)
                    setTimeout(() => {
                      setIsData(true)
                    }, 2000)

                  })
                  .catch(error => {

                  })
              }}
                style={{ marginLeft: 10, width: 150, padding: 20, background: "#3EA7AE", color: "#fff", fontSize: 14, fontWeight: "bold", borderRadius: 12 }}
              >
                Load Data
              </button>
            </>
      }
      <br />
      <br />
      {
        isData && records.length > 0 ?
          <>
            {/* attendance section */}
            <Paper my={20} p="sm" withBorder className="h-fit w-full max-w-4xl">
              <Title
                order={1}
                className="text-lg text-black/[0.85] font-semibold font-poppins capitalize mb-6 "
              >
                attendance
              </Title>

              {records.map((item, i) => (
                <Paper
                  p="sm"
                  key={i}
                  className="mb-2 max-w-2xl bg-luchiPrimary text-white"
                  style={{
                    backgroundColor: i == 0 ? "#3EA7AE" : i % 2 == 0 ? "#3EA7AE" : "#A16208"
                  }}
                >
                  <div className="flex">
                    <Text className="font-medium text-base capitalize  ">
                      {/* {getMonthYear(item.signin_at)} */}
                      Action: {i == 0 ? "Sign In" : i % 2 == 0 ? "Stepped In" : "Stepped Out"}
                    </Text>

                  </div>
                  <Text style={{ textAlign: "right" }} className="font-medium text-base capitalize  ">
                    {/* {getMonthYear(item.signin_at)} */}
                    {item.records_time}
                  </Text>
                </Paper>
              ))}
              <Link
                to={`${pathname}/attendances`}
                className="w-full flex items-center justify-end mt-4"
              >
                {/* <Button
                  variant="white"
                  className="text-[#37A7AE] font-medium text-xs font-poppins capitalize "
                >
                  see more
                </Button> */}
              </Link>


            </Paper>

            {/* leave section */}
            <Paper withBorder p="sm" className="h-fit w-full max-w-4xl ">
              <Title
                order={2}
                className="text-lg text-black/[0.85] font-semibold capitalize mb-6 "
              >
                leave
              </Title>
              {records.length <= 0 ?
                <Paper
                  p="sm"
                  className="mb-2 max-w-2xl bg-yellow-700 text-white"
                  style={{
                    backgroundColor: "#A16208"
                  }}
                >
                  <Text className="font-medium text-base capitalize  ">
                    Department on leave
                  </Text>

                  <Group className="font-normal text-sm " spacing={5}>
                    <Text component="span">Date:</Text>
                    <Text component="span">{document.getElementById("date").value}</Text>

                  </Group>
                </Paper>
                :
                <>
                  <Paper
                    p="sm"
                    className="mb-2 max-w-2xl bg-yellow-700 text-white"
                    style={{
                      backgroundColor: "#3EA7AE"
                    }}
                  >
                    <Text className="font-medium text-base capitalize  ">
                      Department is on Duty
                    </Text>

                    <Group className="font-normal text-sm " spacing={5}>
                      <Text component="span">Date:</Text>
                      <Text component="span">{document.getElementById("date").value}</Text>

                    </Group>
                  </Paper>
                </>
              }
              <Link
                to={`${pathname}/leave`}
                className="w-full flex items-center justify-end mt-4"
              >
                {/* <Button
                  variant="white"
                  className="text-yellow-700 font-medium text-xs font-poppins capitalize "
                >
                  see more
                </Button> */}
              </Link>
            </Paper>

            {/* overtime section */}
            <Paper mt={20} withBorder p="sm" className="h-fit w-full max-w-4xl ">
              <Title
                order={2}
                className=" text-lg text-black/[0.85] font-semibold capitalize mb-6 "
              >
                overtime
              </Title>
              {records.map((item, index) => {
                if (index + 1 !== records.length) {
                  return null
                }
                return (
                  <Paper
                    p="sm"
                    key={index}
                    className="mb-2 max-w-2xl bg-luchiPrimary text-white"
                    style={{
                      backgroundColor: index % 2 == 0 && parseInt(item.records_time.substring(11, 13)) > 15 ? "#A16208" : "#3EA7AE"
                    }}
                  >
                    <Text className="font-medium text-base capitalize  ">
                      {index % 2 == 0 && parseInt(item.records_time.substring(11, 13)) > 15 ? "OVERTIME DETECTED" : "NO OVERTIME"}
                    </Text>
                    {/* <Text className="font-normal text-sm ">Operations department</Text> */}
                    <Box>
                      <Text component="span" className="font-normal text-sm ">
                        Sign Out:
                      </Text>
                      <Text component="span" className="font-normal text-sm ml-1 ">
                        {item.records_time}
                      </Text>
                    </Box>
                  </Paper>
                )
              }


              )}

            </Paper>
          </>
          :
          readers.length > 0 && isData === false ?
            <>
              <Skeleton style={{ width: "100%", height: 150, marginTop: 10, marginBottom: 0 }} count={1} />
              <Skeleton style={{ width: "100%", height: 150, marginTop: 10, marginBottom: 0, }} count={1} />
              <Skeleton style={{ width: "100%", height: 150, marginTop: 10, marginBottom: 0, }} count={1} />
            </>
            : <></>
      }


    </main>
  );
}

function AttendanceClock() {
  const [value, setValue] = useState(new Date());
  const [opened, { open, close }] = useDisclosure(false);
  const [loader, setLoader] = useState(false);
  const [attendance, setAttendance] = useState(false);
  const credRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => setValue(new Date()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  async function attendanceSignin() {
    setLoader(true);
    if (credRef.current.value !== "") {
      const res = await attendanceClockin(credRef.current.value.toUpperCase());
      if (res.status) {
        setAttendance(true);
        successNotify("Notification", "Attendance marked successfully");
        close();
      } else {
        failedNotify("Notification", "Attendance failed");
      }
    } else {
      cautionNotify("notification", "Credential cannot be empty!");
    }
    setLoader(false);
  }
  return (
    <>
      {attendance && (
        <Button
          title="attendance signout"
          variant="white"
          className="bg-red-800 text-white capitalize text-xs mr-2"
        >
          signout
        </Button>
      )}
      <div className="w-fit cursor-pointer" onClick={open}>
        <Clock value={value} size={100} />
      </div>
      <AttendanceSigninModal
        opened={opened}
        close={close}
        method={attendanceSignin}
        loader={loader}
        credRef={credRef}
      />
    </>
  );
}

function AttendanceSigninModal({ opened, close, method, loader, credRef }) {
  return (
    <>
      <Modal
        opened={opened}
        onClose={close}
        withCloseButton={false}
        title="Attendance Signin"
      >
        <ScrollArea className="h-[200px]">
          <Select
            maxDropdownHeight={120}
            label="Credential"
            placeholder="Pick credential"
            size="lg"
            className="w-full"
            data={["Fingerprint", "Mobile_App", "PIN", "RFID_Card"]}
            styles={(theme) => ({
              input: {
                fontSize: 14,
                borderWidth: 1,
                "&:focus": {
                  borderColor: theme.colors.luchiPrimary[0],
                },
              },
              label: {
                paddingBottom: 4,
                fontSize: 14,
              },
              item: {
                fontSize: 14,
                paddingTop: 8,
                paddingBottom: 8,
                "&[data-selected]": {
                  "&, &:hover": {
                    backgroundColor: theme.colors.luchiPrimary[0],
                    color: theme.white,
                  },
                },
                "&[data-hovered]": {},
              },
            })}
            ref={credRef}
          />
          <Box mt={70} className="w-full flex justify-center space-x-4">
            <Button
              fullWidth
              className="bg-red-700 text-white font-medium capitalize"
              type="button"
              onClick={close}
              variant="white"
            >
              cancel
            </Button>
            <Button
              loading={loader}
              loaderPosition="right"
              fullWidth
              color="yellow"
              onClick={method}
              variant="white"
              type="button"
              className="font-medium bg-luchiPrimary text-white capitalize"
            >
              sign
            </Button>
          </Box>
        </ScrollArea>
      </Modal>
    </>
  );
}
