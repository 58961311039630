import {
  Modal,
  Box,
  Button,
  Select,
  NumberInput,
  // ScrollArea,
  // Group,
  // Text,
  Divider,
  Title,
  // Paper,
  TextInput,
} from "@mantine/core";
// import { useContext } from "react";
// import { UserTypeContext } from "../../App";
// import { io } from "socket.io-client";
import { useRef, useState } from "react";
import BranchLocation from "../singleSelect/BranchLocation";
// import dayjs from "dayjs";
import { useCurrentUser } from "../../hooks/useCurrentUser";

export default function EditControlPanelDevice({ opened, close, devices }) {
  // const socket = io("https://api-dev.luchismart.com:7788");
  const { user } = useCurrentUser()
  const ipRef = useRef(null);
  const terminalRef = useRef(null);
  const cpuRef = useRef(null);
  // const portRef = useRef(null);
  const modelNameRef = useRef(null);
  const relayTime = useRef(null);
  // useEffect(() => {
  //   socket.on("connect", () => {
  //     console.log("connected");
  //   });
  //   socket.on("register_device", () => {});
  // }, [socket]);

  let socket;
  console.log(socket);
  const [teamDevice, setTeamDevice] = useState({
    branchLocation: "",
  });
  function handleClick() {
    if (!socket || socket.readyState !== WebSocket.OPEN) {
      // Replace 'ws://device-ip:port' with the actual WebSocket URL of the physical device
      alert(ipRef.current?.value)
      return null
      socket = new WebSocket(`${ipRef.current?.value}:7788`);

      socket.addEventListener("open", (event) => {
        console.log("Connected to the device.");
      });

      socket.addEventListener("message", (event) => {
        const message = event.data;
        // Handle the message from the device
        console.log(message);
      });

      socket.addEventListener("close", (event) => {
        console.log("Connection to the device closed.");
      });

      socket.addEventListener("error", (event) => {
        console.error("WebSocket error:", event);
      });
    } else {
      console.log("Already connected to the device.");
    }
  }

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => { }}
        centered
        withCloseButton={false}
        title="Configure New Control Panel"
        size="lg"
      >
        {/* <Box>
          <Box className="space-y-4 md:flex md:space-x-4 md:items-center md:space-y-0">
            <TextInput
              ref={ipRef}
              size="md"
              className="w-full"
              placeholder="Enter ip address"
              min={1}
              styles={(theme) => ({
                input: {
                  fontSize: 14,
                  borderWidth: 1,
                  "&:focus": {
                    borderColor: theme.colors.luchiPrimary[0],
                  },
                },
                label: {
                  paddingBottom: 4,
                  fontSize: 14,
                },
              })}
            />
            <NumberInput
              ref={cpuRef}
              hideControls
              size="md"
              className="w-full"
              placeholder="Enter cpu serial number"
              min={1}
              styles={(theme) => ({
                input: {
                  fontSize: 14,
                  borderWidth: 1,
                  "&:focus": {
                    borderColor: theme.colors.luchiPrimary[0],
                  },
                },
                label: {
                  paddingBottom: 4,
                  fontSize: 14,
                },
              })}
            />
          </Box>

          <Box className="space-y-4 md:flex justify-end md:space-x-4 md:items-center md:space-y-0">
            <Button
              onClick={handleClick}
              mt={6}
              fullWidth
              maw={200}
              loaderPosition="right"
              color="yellow"
              variant="white"
              className="bg-luchiPrimary text-sm font-medium text-white capitalize "
              type="submit"
            >
              connect
            </Button>
          </Box>
        </Box> */}

        <Divider my={10} />
        <Box className="w-full  ">
          <Title className="text-base capitalize font-poppins font-medium">
            add devices
          </Title>

          <Box className="w-full">
            <Box className="space-y-4 md:flex md:space-x-4 md:items-center md:space-y-0 my-2">
              <TextInput
                hideControls
                id="serial_num"
                ref={terminalRef}
                size="md"
                className="w-full"
                placeholder="Enter terminal serial number"
                min={1}
                styles={(theme) => ({
                  input: {
                    fontSize: 14,
                    borderWidth: 1,
                    "&:focus": {
                      borderColor: theme.colors.luchiPrimary[0],
                    },
                  },
                  label: {
                    paddingBottom: 4,
                    fontSize: 14,
                  },
                })}
              />
            </Box>
            <Box className="space-y-4 md:flex md:space-x-4 md:items-center md:space-y-0 mt-2">
              <BranchLocation setMethod={setTeamDevice} />
              {/* <Select
                ref={modelNameRef}
                className="w-full"
                placeholder="Select device"
                size="md"
                data={devices}
                styles={(theme) => ({
                  item: {
                    fontSize: 14,
                    paddingTop: 8,
                    paddingBottom: 8,
                    "&[data-selected]": {
                      "&, &:hover": {
                        backgroundColor: theme.colors.luchiPrimary[0],
                        color: theme.white,
                      },
                    },
                    fontFamily: theme.fontFamily.poppins[0],
                  },
                  input: {
                    fontSize: 14,
                    borderWidth: 1,
                    "&:focus": {
                      borderColor: theme.colors.luchiPrimary[0],
                    },
                  },
                  label: {
                    paddingBottom: 4,
                    fontSize: 14,
                  },
                })}
              /> */}

              <TextInput
                ref={relayTime}
                hideControls
                id="tag_name"
                size="md"
                className="w-full"
                placeholder="Device Name"
                min={1}
                styles={(theme) => ({
                  input: {
                    fontSize: 14,
                    borderWidth: 1,
                    "&:focus": {
                      borderColor: theme.colors.luchiPrimary[0],
                    },
                  },
                  label: {
                    paddingBottom: 4,
                    fontSize: 14,
                  },
                })}
              />
            </Box>

          </Box>

          <Box className="flex space-x-3 mt-4">
            <Button
              // onClick={handleDisconnect}
              onClick={close}
              fullWidth
              variant="outline"
              className="text-sm font-medium text-red-900 capitalize border-red-800 "
              type="button"
            >
              cancel
            </Button>
            <Button
              onClick={() => {
                // if (
                //   modelNameRef.current?.value &&
                //   terminalRef.current?.value &&
                //   cpuRef.current?.value
                // ) {
                //   handleEmit();
                // }
                const formData = new FormData();
                formData.append("id", user.email)
                formData.append("location", teamDevice.branchLocation)
                formData.append("tag_name", document.getElementById("tag_name").value)
                formData.append("serial_num", document.getElementById("serial_num").value)
                fetch(`https://getintellisoft.com/server/luchitec/createHardware.php`, {
                  method: "POST",
                  body: formData
                })
                  .then(res => res.json())
                  .then(data => {
                    alert(data.message)
                    if (data.code == 200) {
                      close()
                    } else {
                      return null;
                    }
                  })
              }}
              fullWidth
              loaderPosition="right"
              color="yellow"
              variant="white"
              className="bg-luchiPrimary text-sm font-medium text-white capitalize "
              type="submit"
            >
              add device
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

// register a device with the existing connection
// function handleEmit() {
//   socket.emit("register_device", {
//     cmd: "reg",
//     sn: terminalRef.current?.value,
//     cpusn: cpuRef.current?.value,
//     devinfo: {
//       modelname: modelNameRef.current?.value,
//       usersize: 3000,
//       fpsize: 3000,
//       cardsize: 3000,
//       pwdsize: 3000,
//       logsize: 100000,
//       useduser: 1000,
//       usedfp: 1000,
//       usedcard: 2000,
//       usedpwd: 400,
//       usedlog: 100000,
//       usednewlog: 5000,
//       fpalgo: "thbio3.0",
//       firmware: "th600w v6.1",
//       time: dayjs(new Date()).format(),
//       mac: "00-01-A9-01-00-01",
//     },
//   });

//   socket.on("register_device", async (data) => {
//     if (data.result) {
//       const details = {
//         terminal_sn: terminalRef.current?.value,
//         cpu_sn: cpuRef.current?.value,
//         model_name: modelNameRef.current?.value,
//       };
//       const deviceres = await configureDevice(details);
//       console.log(deviceres);
//     }
//   });
// }

// function handleDisconnect() {
//   // socket.disconnect("disconnect", () => {
//   // console.log("🔥: Control panel disconnected");
//   // });
//   socket.on("disconnect", () => {
//     console.log("🔥: A user disconnected");
//   });
//   close();
// }

// function handleConnect() {
//   socket.connect(ipRef.current?.value, async (socket) => {
//     console.log(socket);
//     const details = {
//       ip_address: ipRef.current?.value,
//       port_number: portRef.current?.value,
//     };
//     const res = await configureDevice(details);
//     console.log(res);
//   });
// }

// const connectButton = document.getElementById("connectButton");
// const messages = document.getElementById("messages");

// let socket; // WebSocket object

// connectButton.addEventListener("click", () => {
//   if (!socket || socket.readyState !== WebSocket.OPEN) {
//     // Replace 'ws://device-ip:port' with the actual WebSocket URL of the physical device
//     socket = new WebSocket("ws://device-ip:port");

//     socket.addEventListener("open", (event) => {
//       console.log("Connected to the device.");
//     });

//     socket.addEventListener("message", (event) => {
//       const message = event.data;
//       // Handle the message from the device
//       messages.innerHTML += `<p>${message}</p>`;
//     });

//     socket.addEventListener("close", (event) => {
//       console.log("Connection to the device closed.");
//     });

//     socket.addEventListener("error", (event) => {
//       console.error("WebSocket error:", event);
//     });
//   } else {
//     console.log("Already connected to the device.");
//   }
// });
