import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PositionSelect from "../../../components/singleSelect/PositionSelect";
import { useContext } from "react";
import { UserTypeContext } from "../../../App";
import Cookies from "js-cookie";
import { Box, Button, Group, TextInput } from "@mantine/core";
import StatusSelect from "../../../components/singleSelect/StatusSelect";
import { isEmail, isNotEmpty, useForm } from "@mantine/form";
import { DepartmentLocation } from "../../../components/singleSelect/DepartmentLocation";
import DepartmentSelect from "../../../components/singleSelect/DepartmentSelect";
import { createEmployee, editEmployee } from "../../../services/requests";
import {
  cancelEdit,
  failedNotify,
  successNotify,
} from "../../../services/functions";
import CredentialsSwitch from "../../../components/CredentialsSwitch";
import LocalBreadcrumb from "../../../components/breadcrumb/LocalBreadcrumb";
import { useCurrentUser } from "../../../hooks/useCurrentUser";
import { TempValueContext } from "../../../context/TempValueContext";
import { mutate } from "swr";

const Addemployee = () => {
  const { tempValue: employeeEditInfo, setTempValue } =
    useContext(TempValueContext);
  const { user } = useCurrentUser();
  const [branchID, setBranchID] = useState(
    user?.user_type !== "super_admin" ? user?.branch?.id : ""
  );
  const navigate = useNavigate();
  const userType = useContext(UserTypeContext).userType;
  const [loader, setLoader] = useState(false);
  const form = useForm({
    initialValues: {
      staffName: "",
      staffID: "",
      cardNumber: "",
      positionID: "",
      deptID: "",
      email: "",
      status: "",
      deptLocationID: branchID,
      credentials: {
        FINGERPRINT: false,
        RFID_CARD: false,
        MOBILE_APP: false,
        PIN: false,
      },
    },

    validate: {
      staffName: isNotEmpty("Compulsory field"),
      staffID: isNotEmpty("Compulsory field"),
      positionID: isNotEmpty("Compulsory field"),
      deptID: isNotEmpty("Compulsory field"),
      email: isEmail("Compulsory field"),
      status: isNotEmpty("Compulsory field"),
      deptLocationID: isNotEmpty("Compulsory field"),
    },
  });
  const newBranchID = form.getInputProps("deptLocationID").value;
  useEffect(() => {
    if (employeeEditInfo.id) {
      form.setValues({
        staffName: employeeEditInfo.name,
        staffID: employeeEditInfo.staff_id ?? "",
        cardNumber: employeeEditInfo.card_number ?? "",
        positionID: employeeEditInfo.position.id,
        deptID: employeeEditInfo.department.id,
        email: employeeEditInfo.email,
        status: employeeEditInfo.status,
        deptLocationID: employeeEditInfo.branch.id,
        credentials: { ...employeeEditInfo.credentials },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeEditInfo]);

  useEffect(() => {
    if (newBranchID) {
      setBranchID(newBranchID);
    }
  }, [newBranchID]);

  const handleCreateEmployee = async (values, e) => {
    e.preventDefault();
    setLoader(true);
    const details = {
      employeeID: employeeEditInfo.id ? employeeEditInfo.id : "",
      staff_id: values.staffID,
      card_number: values.cardNumber,
      name: values.staffName,
      email: values.email,
      branch_id: values.deptLocationID,
      department_id: values.deptID,
      position_id: values.positionID,
      phone_number: employeeEditInfo.id
        ? employeeEditInfo.phone_number
        : "2007000000000",
      status: values.status,
      credentials: values.credentials,
    };
   
      const payload = new FormData();
      payload.append("name", values.staffName)
      payload.append("phone", "2007000000000")
      payload.append("cardnumber", values.cardNumber);
      payload.append("email", values.email);
      payload.append("adminemail", localStorage.getItem("userEmail"))
      try {
        
        const response = await fetch("https://payload-x.com/luchismart/saveUser.php", {
          method: "POST",
          body: payload,
        });

        if (!response.ok) {
          
          throw new Error(`HTTP error! status: ${response.status}`);
          
        }

        const result = await response.json(); // Assuming the server responds with JSON
        console.log("User saved successfully:", result);
        
      } catch (error) {
        console.error("Failed to save user:", error);
        
      }
    
    
    
    if (employeeEditInfo?.id) {
      const updateRes = await editEmployee(details);
      if (updateRes.status) {
        successNotify("Edit status", updateRes.message);
        setTempValue("");
        mutate("api/all-employees");
        setTimeout(() => navigate("/team/employees"), 1500);
      } else failedNotify("Edit status", updateRes.response.data.message);
    } else {
      const createRes = await createEmployee(details);
      if (createRes.status) {
        successNotify("Create status", createRes.message);
        mutate("api/all-employees");
        
        
        setTimeout(() => navigate("/team/employees"), 1500);
      } else if (createRes?.response) {
        console.error(createRes.response);
        failedNotify("Create status", createRes.response.data.message);
      }
    }
    setLoader(false);
  };

  return (
    <div className="w-full 3xl:max-w-[1222px] bg-white pb-8">
      <div className="w-full max-w-[700px] pb-8">
        <Box>
          <LocalBreadcrumb
            prev="team"
            current="add employee"
            link="team"
            header="add employee"
          />
        </Box>

        <form
          onSubmit={form.onSubmit((values, e) =>
            handleCreateEmployee(values, e)
          )}
          className="w-full max-w-lg mt-5"
        >
          <TextInput
            placeholder="Enter your full name"
            label="Staff Name"
            size="lg"
            radius={4}
            styles={(theme) => ({
              label: {
                paddingBottom: 4,
                fontSize: 16,
                fontWeight: 500,
              },
              input: {
                fontSize: 14,
                borderWidth: 1,
                "&:focus": {
                  borderColor: theme.colors.luchiPrimary[0],
                },
              },
            })}
            {...form.getInputProps("staffName")}
          />
          <Box
            my={16}
            className="w-full space-y-4 md:space-y-0 md:flex md:space-x-4"
          >
            <TextInput
              placeholder="Enter staff Id"
              label="Staff ID"
              size="lg"
              className="w-full"
              radius={4}
              styles={(theme) => ({
                label: {
                  paddingBottom: 4,
                  fontSize: 16,
                  fontWeight: 500,
                },
                input: {
                  fontSize: 14,
                  borderWidth: 1,
                  "&:focus": {
                    borderColor: theme.colors.luchiPrimary[0],
                  },
                },
              })}
              {...form.getInputProps("staffID")}
            />
            <PositionSelect form={form} />
          </Box>
          {userType === "super_admin" && <DepartmentLocation form={form} />}

          <Box
            mt={16}
            className="w-full space-y-4 md:space-y-0 md:flex md:space-x-4"
          >
            <TextInput
              placeholder="Enter email"
              label="Email"
              className="w-full"
              size="lg"
              radius={4}
              styles={(theme) => ({
                label: {
                  paddingBottom: 4,
                  fontSize: 16,
                  fontWeight: 500,
                },
                input: {
                  fontSize: 14,
                  borderWidth: 1,
                  "&:focus": {
                    borderColor: theme.colors.luchiPrimary[0],
                  },
                },
              })}
              {...form.getInputProps("email")}
            />
            <TextInput
              placeholder="Enter card number"
              label="Staff Card Number"
              size="lg"
              className="w-full"
              radius={4}
              styles={(theme) => ({
                label: {
                  paddingBottom: 4,
                  fontSize: 16,
                  fontWeight: 500,
                },
                input: {
                  fontSize: 14,
                  borderWidth: 1,
                  "&:focus": {
                    borderColor: theme.colors.luchiPrimary[0],
                  },
                },
              })}
              {...form.getInputProps("cardNumber")}
            />
          </Box>
          <Box
            my={16}
            className="w-full space-y-4 md:space-y-0 md:flex md:space-x-4"
          >
            <StatusSelect form={form} />
            <DepartmentSelect form={form} branchID={branchID} />
          </Box>

          <Box>
            <CredentialsSwitch form={form} />
          </Box>

          <Group mt={50} position="center">
            {employeeEditInfo.id && (
              <Button
                onClick={() =>
                  cancelEdit(setTempValue, navigate, "/team/employees")
                }
                type="button"
                variant="white"
                className="bg-red-700 text-white text-sm font-bold capitalize  "
                size="md"
                radius={4}
              >
                cancel
              </Button>
            )}
            <Button
              type="submit"
              variant="white"
              className="bg-luchiPrimary text-white text-sm font-medium font-poppins capitalize  "
              size="md"
              radius={4}
              loading={loader}
              loaderPosition="right"
            >
              add employee
            </Button>
          </Group>
        </form>
      </div>
    </div>
  );
};

export default Addemployee;
